<div
    class="separator-container"
    *ngIf="isFirstElement()"
    dndDropzone
    [ngClass]="{ 'not-assignable': preventAssignmentInParent() }"
    [dndDisableIf]="!canAssignContentInParent()"
    (dndDrop)="checkAssignContentToUser(0)"
>
    <div class="separator"></div>
</div>
<mat-expansion-panel
    class="assignment-container"
    *ngIf="assignment"
    [ngClass]="{ disabled: isDisabled(), 'not-assignable': preventAssignment() }"
    [hideToggle]="true"
    (opened)="toggleIcon()"
    (closed)="toggleIcon()"
    dndDropzone
    (dndDrop)="checkAssignContentToUser(undefined)"
    (dndStart)="startDrag()"
    (dndEnd)="endDrag()"
    [dndDraggable]="assignment"
    [dndDisableIf]="isGroupAssignment()"
    dndDragover
>
    <mat-expansion-panel-header [collapsedHeight]="'50px'" [expandedHeight]="'50px'">
        <div class="header" [tooltip]="getAssignmentTooltip()" tooltipPosition="above-right">
            <div class="dragover-message" *ngIf="getForbiddenAssignmentTooltipMessage()">
                <span class="icon-Warning"></span>{{ getForbiddenAssignmentTooltipMessage() }}
            </div>
            <div class="order-number" *ngIf="assignment.orderNumber">
                {{ assignment.orderNumber }}
            </div>
            <div class="icon" [ngClass]="getIcon()" (click)="selectAssignment($event)"></div>
            <div class="title" [ngClass]="getTextColorStyle()">
                <div class="name">{{ assignment.title }}</div>
                <div class="type" *ngIf="assignment.type">({{ getTypeLabel() }})</div>
                <div class="completion" *ngIf="assignment.level">
                    ({{ assignment.completion }}%)
                </div>
            </div>
            <div class="icons">
                <div class="hover-icons">
                    <div class="notation" *ngIf="notePanelOpened">
                        <input
                            type="number"
                            step="1"
                            min="0"
                            max="100"
                            [(ngModel)]="assignment.note"
                        />/ 100
                        <div class="notation-button" (click)="postNote(true)">Réussi</div>
                        <div class="notation-button failed" (click)="postNote(false)">
                            Non réussi
                        </div>
                        <div class="icon-Fermerdetails" (click)="toggleNotePanel()"></div>
                    </div>
                    <div
                        class="icon-reporting"
                        *ngIf="isTestCognitifN4S()"
                        (click)="viewChart($event)"
                        [tooltip]="'Voir les stats'"
                    ></div>
                    <div
                        class="notation-button"
                        *ngIf="showNotationButton() && !isTutor()"
                        (click)="toggleNotePanel()"
                    >
                        Noter
                    </div>
                    <div
                        class="icon-signature"
                        *ngIf="showCertificate()"
                        (click)="getCertificate($event)"
                        [tooltip]="'Télécharger l\'attestation'"
                    ></div>
                    <div
                        class="icon-guide"
                        *ngIf="hasGuide()"
                        (click)="viewGuide($event)"
                        [tooltip]="'Voir le guide'"
                    ></div>
                    <div
                        class="icon-assigner"
                        *ngIf="isDisabled()"
                        (click)="showAssignment($event)"
                        [tooltip]="'Réassigner'"
                    ></div>
                    <div
                        class="icon-desassigne"
                        *ngIf="!isDisabled() && canHideAssignment() && !isTutor()"
                        (click)="hideAssignment($event)"
                        [tooltip]="'Désassigner'"
                    ></div>
                    <div
                        class="icon-Fiche"
                        (click)="openContentDialog($event)"
                        [tooltip]="'Voir la fiche'"
                    ></div>
                    <div
                        class="icon-Valide"
                        *ngIf="enabledValidationButton() && !isTutor()"
                        (click)="validateAssignment($event)"
                        [tooltip]="'Forcer la validation'"
                    ></div>
                    <div
                        class="icon-ForcerInvalidation"
                        *ngIf="enabledInvalidationButton() && !isTutor()"
                        (click)="invalidateAssignment($event)"
                        [tooltip]="'Annuler: Forcer la validation'"
                    ></div>
                    <div
                        class="icon-rafraichir"
                        *ngIf="!isDevoir() && enabledResetTrackingButton() && !isTutor()"
                        (click)="resetTracking($event)"
                        [tooltip]="'Proposer une nouvelle tentative'"
                    ></div>
                    <div
                        class="icon-rafraichir"
                        *ngIf="isDevoir() && isCompleted() && !isTutor()"
                        (click)="resetTrackingDevoir($event)"
                        tooltip="Proposer un nouveau dépôt"
                    ></div>
                    <div
                        class="icon-Miseenapplcation"
                        *ngIf="canStartReview()"
                        (click)="startReview()"
                        [tooltip]="'Voir la copie de l\'apprenant'"
                    ></div>
                    <div
                        class="icon-voir"
                        *ngIf="isActivity()"
                        (click)="openActivity()"
                        [tooltip]="getLaunchButtonTooltip()"
                    ></div>
                    <div
                        class="icon-radar"
                        *ngIf="isQuiz() || !isActivity()"
                        (click)="openReportingUser($event)"
                        [tooltip]="getReportingTooltip()"
                    ></div>
                    <div
                        class="icon-bin"
                        *ngIf="!isGroupAssignment() && !isTutor()"
                        (click)="deleteAssignment($event)"
                        [tooltip]="'Supprimer'"
                    ></div>
                    <div
                        class="icon-message"
                        *ngIf="isNotCompleted()"
                        (click)="remindWork($event)"
                        [tooltip]="'Envoyer un rappel à l\'apprenant'"
                    ></div>

                    <div
                        class="icon-Corrige"
                        *ngIf="isDevoir() && isCompleted()"
                        (click)="openUserTrackingDialog($event)"
                        [tooltip]="'Voir le devoir et noter'"
                    ></div>
                    <div
                        class="icon-formateur-referent"
                        *ngIf="!isReferent() && !isTutor() && !isDisabled()"
                        (click)="markAsReferent($event)"
                        [tooltip]="'Devenir référent'"
                    ></div>
                    <div
                        class="icon-formateur-referent-off"
                        *ngIf="isReferent() && !isTutor() && !isDisabled()"
                        (click)="unmarkAsReferent($event)"
                        [tooltip]="'Ne plus être référent'"
                    ></div>
                    <div
                        class="icon-calendrier"
                        *ngIf="!isTutor()"
                        (click)="openAddDisponibilityDialog($event)"
                        [tooltip]="getAddDisponibilityTooltip()"
                    ></div>
                    <div
                        class="icon-calendrier-annuler"
                        *ngIf="showDisponibilityIcon() && !heritedDisponibility && !isTutor()"
                        (click)="removeDisponibility($event)"
                        [tooltip]="'Supprimer la restriction calendaire'"
                    ></div>
                </div>
                <div class="default-icons">
                    <div class="icon-nouveau" [tooltip]="getAssignmentDate()"></div>
                    <div
                        class="score"
                        *ngIf="canViewScore()"
                        [ngClass]="getTextColorStyle()"
                        [tooltip]="getScoreTooltip()"
                    >
                        <div *ngIf="assignment.previousAttempts" class="icon-voir-details"></div>
                        {{ assignment.tracking.score }}/100
                    </div>
                    <div
                        class="icon-desassigne"
                        *ngIf="isDisabled()"
                        [tooltip]="'Assignation désactivée'"
                    ></div>
                    <div
                        class="icon-Warning"
                        *ngIf="getIso() < 1 && !isDisabled()"
                        [tooltip]="getIsoWarning()"
                        [ngClass]="{ red: getIso() === 0, orange: getIso() === -1 }"
                    ></div>
                    <div
                        class="icon-calendrier-actif"
                        *ngIf="showDisponibilityIcon()"
                        [tooltip]="getDisponibilityTooltip()"
                        [ngClass]="getDisponibilityClass()"
                    >
                        <div class="icon-Warning" *ngIf="showWarningDisponibility()"></div>
                    </div>
                    <div
                        class="tracking"
                        [tooltip]="getTrackingStatusText()"
                        [ngClass]="[getTrackingStatusIconStyle(), getTextColorStyle()]"
                    ></div>
                    <div
                        class="icon-formateur-referent"
                        *ngIf="!getReferentAvatar()"
                        [tooltip]="getReferentTooltip()"
                        [ngClass]="{ blue: isReferent() }"
                    ></div>
                    <div class="avatar" *ngIf="getReferentAvatar()">
                        <img
                            class="avatar"
                            [src]="getReferentAvatar()"
                            [tooltip]="getReferentTooltip()"
                        />
                    </div>
                    <div
                        class="icon-Contenupayant"
                        *ngIf="hasPrice()"
                        [tooltip]="'Contenu payant'"
                    ></div>
                    <div
                        class="icon-OrdoOn"
                        *ngIf="isOrdered()"
                        [tooltip]="'Contenu ordonné'"
                    ></div>
                    <div
                        class="icon-AssignationDeGroupe"
                        *ngIf="isGroupAssignment()"
                        [tooltip]="'Assignation de groupe'"
                    ></div>
                    <div class="icon-down" *ngIf="!isActivity()" [ngClass]="{ open: opened }"></div>
                    <div class="blank" *ngIf="isActivity()"></div>
                </div>
            </div>
        </div>
    </mat-expansion-panel-header>
    <ng-template
        matExpansionPanelContent
        *ngIf="assignment.level && assignment.level !== 'activity'"
    >
        <app-user-assignments-entry
            *ngFor="let child of getSortedChildren(); index as i"
            class="expansion-panel-child"
            [assignment]="child"
            [parent]="assignment"
            [referents]="referents"
            [user]="user"
            [index]="i"
            (assignmentDeleted)="removeAssignment($event)"
        ></app-user-assignments-entry>
    </ng-template>
</mat-expansion-panel>
<!-- [ngStyle]="getRemainingHeight()" -->
<div
    class="separator-container"
    dndDropzone
    [dndDisableIf]="!canAssignContentInParent()"
    [ngClass]="{ 'not-assignable': preventAssignmentInParent() }"
    (dndDrop)="checkAssignContentToUser(index + 1)"
>
    <div class="no-assignment-dropzone" *ngIf="index === undefined">
        Déposez ici les contenus de la bibliothèque à assigner.
    </div>
    <div class="separator"></div>
</div>
